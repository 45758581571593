
import axios from 'axios';
export default {
    
    async createShopItemMoves(params)  {
        return await axios.post(`shop_item_moves/create` , params)
    },
    async createShopItemMovesList(params)  {
        return await axios.post(`shop_item_moves/create/list` , params)
    },
    async updateShopItemMovesColumn(column , value , data)  {
        return await axios.put(`shop_item_moves/update_list?${column}=${value}` , data)
    },
    async deleteShopItemMovesList(list)  {
        return await axios.delete(`shop_item_moves/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportShopItemMoves(column , value)  {
        return await axios.get(`shop_item_moves/report?${column}=${value}`)
    },
    async getAllShopItemMoves()  {
        return await axios.get(`shop_item_moves/all`)
    },
    async getOneShopItemMoves(shop_item_move_id)  {
        return await axios.get(`shop_item_moves/all/${shop_item_move_id}`)
    },
    async getShopItemMovesByColumn(column , value)  {
        return await axios.get(`shop_item_moves/filter?column=${column}&value=${value}`)
    },
    async deleteShopItemMoves(shop_item_move_id)  {
        return await axios.delete(`shop_item_moves/delete/${shop_item_move_id}`)
    },
    async updateShopItemMoves(shop_item_move_id , params)  {
        return await axios.put(`shop_item_moves/update/${shop_item_move_id}` , params)
    }
}