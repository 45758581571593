
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
            <v-container v-else class="my-2">
                <form @submit.prevent="addShopItemMoves" autocomplete="off">
                    <v-layout row wrap>
        
                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="['add','loss']" v-model="shop_item_moves.shop_item_move_type" dense  filled outlined  item-text="shop_item_move_type"
                                    item-value="shop_item_move_type" :return-object="false" :label="$store.getters.language.data.shop_item_moves.shop_item_move_type">
                                </v-select>
                        </v-flex>
                
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="shop_item_moves.shop_item_move_note" type="text" :label="$store.getters.language.data.shop_item_moves.shop_item_move_note" dense
                            class="mx-1"  filled outlined >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="shop_item_moves.shop_item_move_date" type="datetime-local" :label="$store.getters.language.data.shop_item_moves.shop_item_move_date" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="shop_item_moves.shop_item_move_quantity" type="number" :label="$store.getters.language.data.shop_item_moves.shop_item_move_quantity" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="shop_items" v-model="shop_item_moves.shop_item_id" dense  filled outlined  item-text="shop_item_id"
                                    item-value="shop_item_id" :return-object="false" :label="$store.getters.language.data.shop_items.shop_item_id">
                                </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="users" v-model="shop_item_moves.user_id" dense  filled outlined  item-text="user_id"
                                    item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_id">
                                </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md2 sm4>
                            <v-btn  color="primary" :loading="loading_btn" type="submit" >{{$store.getters.language.data.shop_item_moves.add_btn}}</v-btn>
                        </v-flex>
                    </v-layout>
                </form>
<v-layout row wrap mt-5>
    <v-flex xs12>
        <v-card>
            <v-card-text>
                <v-data-table :headers="headers"  show-select v-model="selected_rows"    :search="search" :items="rows" class="elevation-0"
                    item-key="shop_item_move_id">
                    <template v-slot:[`item.shop_item_move_id`]="{ item }">
                        <div>
                            <v-btn icon :to="'/shop_item_moves-list/'+item.shop_item_move_id"  color="teal" class="mx-1">
                            <v-icon> mdi-pencil-outline </v-icon>
                            </v-btn>
                            <v-btn color="error" icon class="mx-1" @click="selectShopItemMoves(item)" >
                                <v-icon> mdi-delete-outline </v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
            
            <v-card-actions v-if="selected_rows.length > 0">
                <v-btn color="error" @click="deleteShopItemMovesList">{{$store.getters.language.data.shop_item_moves.delete_list_btn}}</v-btn>
            </v-card-actions>
                
        </v-card>
    </v-flex>
</v-layout>
</v-container>
<v-dialog v-model="delete_dialog" persistent max-width="400">
    <v-card>
        <v-card-title>
            {{$store.getters.language.data.shop_item_moves.delete_question}}
        </v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text class="elevation-0" @click="delete_dialog = false">
                {{$store.getters.language.data.shop_item_moves.cancel_btn}}
            </v-btn>
            <v-btn color="error" class="elevation-0" @click="deleteShopItemMoves(selected_shop_item_moves.shop_item_move_id)">
                {{$store.getters.language.data.shop_item_moves.yes_btn}}
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
<v-snackbar v-model="snackbar.value" :color="snackbar.color">
    {{snackbar.text}}
</v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/shop_item_moves.request.js'
    export default {
        data() {
            return {
                shop_item_moves: {},
                search: '',
                loading : true,
                loading_btn:false,
                selected_rows : [],
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                rows:[],
                selected_shop_item_moves : {},
                delete_dialog: false,
                headers: [

                    
                        { 
                            text: this.$store.getters.language.data.shop_item_moves.shop_item_move_type,
                            align: 'start',
                            sortable: true,
                            value: 'shop_item_move_type',
                        },
                        { 
                            text: this.$store.getters.language.data.shop_item_moves.shop_item_move_note,
                            align: 'start',
                            sortable: true,
                            value: 'shop_item_move_note',
                        },
                        { 
                            text: this.$store.getters.language.data.shop_item_moves.shop_item_move_date,
                            align: 'start',
                            sortable: true,
                            value: 'shop_item_move_date',
                        },
                        { 
                            text: this.$store.getters.language.data.shop_item_moves.shop_item_move_quantity,
                            align: 'start',
                            sortable: true,
                            value: 'shop_item_move_quantity',
                        },
                    {
                        text: this.$store.getters.language.data.shop_items.shop_item_id,
                        align: 'start',
                        sortable: true,
                        value: 'shop_item_id',
                    },
                    {
                        text: this.$store.getters.language.data.users.user_id,
                        align: 'start',
                        sortable: true,
                        value: 'user_id',
                    },   {
            text: this.$store.getters.language.data.actions,
            align: 'start',
            sortable: true,
            value: 'shop_item_move_id',
        }
                ],
            }
        },
        computed: {
            shop_items(){
                return this.$store.getters.shop_items_list
            },
            users(){
                return this.$store.getters.users_list
            },
        },
        mounted(){
            this.readShopItemMoves();
        },
        methods: {
            addShopItemMoves() {
                this.loading_btn = true
                requests.createShopItemMoves(this.shop_item_moves).then(r => {
                    if (r.status == 200) {
                        this.shop_item_moves = {}
                        this.rows.push(
                            r.data.new_data
                        )
                        this.snackbar = {
                            value: true,
                            text: 'ShopItemMoves Added',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to add ShopItemMoves',
                            color: 'error'
                        }
                    }
                })
                .finally(() => {
                    this.loading_btn = false
                })

            },
            deleteShopItemMoves(shop_item_move_id) {
                requests.deleteShopItemMoves(shop_item_move_id).then(r => {
                    this.delete_dialog = false
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f =>{
                            return f.shop_item_move_id != shop_item_move_id
                        })
                        this.snackbar = {
                            value: true,
                            text: 'ShopItemMoves Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },
            
            deleteShopItemMovesList() {
                let ids = this.selected_rows.map(m => m.shop_item_move_id)
                requests.deleteShopItemMovesList(ids).then(r => {
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f =>{  
                            return ids.indexOf(f.shop_item_move_id) == -1
                        })
                        this.snackbar = {
                            value: true,
                            text: this.selected_rows.length+' ShopItemMoves Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },
            
            readShopItemMoves() {
                this.loading = true
                requests.getAllShopItemMoves().then(r => {
                    if (r.status == 200) {
                        this.rows = r.data.rows
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read ShopItemMoves',
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Fail to read ShopItemMoves',
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            selectShopItemMoves(i) {
                this.selected_shop_item_moves = i
                this.delete_dialog = true
            },
        },
    }
</script>
                    